import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import WebAppDevelopment from '~/views/Services/WebAppDevelopment/WebAppDevelopment.view'

const WebAppDevelopmentPage = () => <WebAppDevelopment />

export default WebAppDevelopmentPage

export const query = graphql`
  query {
    strapiService(page_name: { eq: "Web Application Development" }) {
      ...MetaTagInformationServices
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiService>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/web-application-development/"
      isBreadcrumbs
      secondLevel="Services"
      secondLevelPath="/services/"
      current="Web Application Development Services"
      currentPath="/services/web-application-development/"
    />
  )
}
